.sara-middle{
    text-align: center;
    color: #00404E;
    font-size: 14px;
    background-color: #d9d9d934;
    padding: 20px 0;
}
.sara-middle > p {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
}
.sara-top-new{
    width: 55%;
    margin: 0 auto;
}
.sara-img{
    width: 100%;
    margin: 40px 0 0 0;
}
.sara-top-new > h1, .sara-top-new > h4, .conosciamo-sara > h1, .conosciamo-sara > h4 {
    font-weight: 500;
}
.sara-img > img {
    width: 100%;
}
.sara-top-new > button {
    background-color: #F37E0E;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 8px 0;
    color: white;
    font-size: 18px;
    width: 100%;
}
.sara-middle hr {
    border-color: #00404e1f;
}
.sara-middle > h2 {
    font-size: 24px;
    font-weight: 500;
}
.sara-middle h4 {
    margin-bottom: 20px;
    font-weight: 400;
    color: #F37E0E;
    font-size: 18px;
}
.conosciamo-sara{
    width: 55%;
    margin: 0 auto;
    margin-bottom: 60px;
    margin-top: 45px;
}
.conosciamo-sara > img {
    width: 100%;
}
.conosciamo-sara > h4 {
    color: #044B5A;
}
.giorni-container{
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 0.6rem; 
   padding: 20px 0;
   width: 50%;
   margin: 0 auto;
}
.giorni-container > div {
    color: #00404E;
    background-color: #fff;
    border-radius: 2px;
    display: flex;
    box-shadow: 0 0 10px 1px #0000003b;
    justify-content: space-around;
    padding: 5px;
    width: 18%;
    min-width: 18%;
    text-align: left;
    cursor: pointer;
}
.giorni-container > div > div:first-of-type{
    font-size: 18px !important;
    font-weight: 500;
}
.giorni-container .oggi {
    background-color: #044B5A;
    color: #fff;
}
.giorni-container .weekend {
    box-shadow: 0 0 10px 1px #00000036;
    color: #00404e6f;
}
.giorni-container .weekend:hover{
    cursor: not-allowed;
}
.giorni-container > div > div:first-of-type {
    font-size: 12px;
}
.giorni-container > div > div:last-of-type {
    font-size: 16px;
    font-weight: 500;
}
.giorni-container > div > div > div:last-of-type {
    font-weight: 400;
}
.giorni-container > div > div > div:first-of-type {
    color: #F37E0E;
}
.weekend > div > div:first-of-type{
     color: #f37d0e8a !important;
}
.hour-container{
    /*background-color: #f0f0f0;*/
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px 1px #00000025;
}
.hour-container > img {
    width: 20%;
}
.hour-container > h4 {
    color: #044B5A;
}
.hour-container > div:last-of-type{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.hour-container > div:last-of-type > p {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #00404eb7;
    font-size: 13px;
}
.hour-container > div:last-of-type img {
    width: 20px;
}

.cta-sara{
    padding: 30px 0;
}
.cta-sara > button{
    background-color: #F37E0E;
    color: white;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    gap: 10px;
    justify-content: center;
    width: 50%;
    padding: 9px 0;
    font-size: 16px;
    margin-bottom: 30px;
    cursor: pointer;
}
.cta-sara hr {
    border-color: #00404e1f;
}
.cta-sara > p {
    width: 35%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #00404eb7;
}
.select-orario{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.select-orario select {
    padding: 8px;
    height: 55px;
    font-size: 17px;
    border: 1px solid #F37E0E;
    box-shadow: 0 0 1px 3px #ff682d30 !important;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
}
.slider-controls{
    display: none;
}
.thanks-cont p {
    color: #00404E;
}
.thanks-cont > p {
    text-align: center;
}
.recap-sara{
    background-color: #D9D9D9;
    padding: 20px 0;
    text-align: center;
    margin-top: 30px;
}
.recap-sara > p {
    width: 60%;
    margin: 0 auto;
}
.recap-sara > div {
    border: 1px solid #F37E0E;
    border-radius: 5px;
    width: 25%;
    margin: 0 auto;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 1px 3px #ff682d30;
}
.recap-sara > div > p {
    font-size: 24px;
    font-weight: 500;
    color: #F37E0E;
}
.sara-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}
.sara-bottom > img {
    width:10%;
    margin-top: 20px;
}
.sara-bottom > p {
    font-size: 18px;
    font-weight: 500;
    color: #00404E;
    text-align: center;
    width: 60%;
}

@media screen and (max-width: 600px){
    .slider-controls {
        display: block;
    }
    .sara-top-new{
        width: 85%;
        margin: 0 auto;
    }
    .sara-top-new > .h1-comp{
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .conosciamo-sara{
        margin-top: 45px;
    }
    .conosciamo-sara > .h1-comp{
        margin-top: 0;
    }
    .conosciamo-sara > .h1-tit{
        margin-top: 15px;
    }
    .sara-middle > h2 {
        margin-top: 0;
        font-size: 28px;
    }
    .sara-middle > h4 {
        margin-top: -10px;
    }
    .giorni-container > div > div:first-of-type{
        font-size: 28px !important;
        font-weight: 500;
        width: 10%;
    }
    .giorni-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0.6rem; 
        padding: 20px 0;
        width: 100%;
     }
     .hour-container > img {
        display: none;
    }
    .hour-container{
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        background-color: #fff;
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 2px 20px;
        border-radius: 5px;
        box-shadow: 0 0 10px 1px #00000025;
    }
    .hour-container > h4{
        font-size: 28px;
        text-align: left;
        width: 40%;
        margin-top: 10px;
    }
    .giorni-container{
        margin-top: -15px;
    }
     .giorni-container > div {
        color: #00404E;
        background-color: #fff;
        border-radius: 2px;
        display: flex;
        box-shadow: 0 0 10px 1px #0000003b;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;
        padding: 5px;
        width: 80%;
        padding: 15px 20px;
    }
    .giorni-container > div > div:last-of-type {
        display: flex;
        gap: 0px;
        flex-direction: column;
    }
     .cta-sara > button:first-of-type{
        background-color: #F37E0E;
        color: white;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        gap: 10px;
        justify-content: center;
        width: 85%;
        padding: 9px 0;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    .cta-sara hr {
        border-color: #00404e1f;
    }
    .cta-sara > p {
        width: 95%;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 12px;
        color: #00404eb7;
    }
    .recap-sara{
        background-color: #D9D9D9;
        padding: 20px 0;
        text-align: center;
    }
    .recap-sara > p {
        width: 60%;
        margin: 0 auto;
    }
    .recap-sara > div {
        border: 1px solid #F37E0E;
        border-radius: 5px;
        width: 50%;
        margin: 0 auto;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        box-shadow: 0 0 1px 3px #ff682d30;
    }
    .conosciamo-sara{
        width: 95%;
        margin: 0 auto;
        margin-bottom: 30px;
        margin-top: 45px;
    }
    .sara-bottom > img {
        width:20%;
        margin-top: 20px;
    }
}
