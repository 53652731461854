@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.top-scheda-corso{
    background-color: #F37E0E;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: 'Inter', sans-serif;
}
.top-scheda-corso h2 {
    color: white;
    font-size: 18px;
    font-weight: 400;
}
.red-other {
    width: 70%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 40px;
}
.red-other > p {
    background-color: rgba(202, 50, 50, 1);
    padding: 7px 12px;
    font-size: 14px;
    text-align: center;
    color: white;
    font-family: 'Inter' , sans-serif;
    width: 40%;
}
.red-other > p > span {
    text-decoration: underline;
}

.image-logo-mobile, .image-logo-mobile2{
    display: none;
}
.image-logo{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.image-logo > img:first-of-type {
    width: 30%;
    height: auto;
}
.image-logo > img:last-of-type {
    width: 50%;
    height: auto;
}
.image-logo > h3 {
    color: rgba(0, 75, 90, 1);
    font-size: 18px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
}

.title-scheda-corso{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
}
.title-scheda-corso > p {
    color: rgba(0, 75, 90, 1);
    font-weight: 600;
    font-size: 13px;
    margin: 0;
}
.title-scheda-corso > h1,
.intestazione > h1 {
    color: rgba(0, 75, 90, 1);
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 500;
    width: 50%;
}
.intestazione{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}
.tag-scheda-corso{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    gap: 1rem;
}
.tag-scheda-corso > div {
    width: 70%;
}
.tag-scheda-corso span {
    padding: 5px 10px;
    margin: 0 10px;
    color: #F37E0E;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    border-radius: 30px;
    border: 1px solid #F37E0E;
}
.line{
    width: 80%;
    margin: 0 auto;
    color: rgba(0, 75, 90, 0.2);
}

.description-scheda-corso{
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 80%;
    font-family: 'Inter', sans-serif;
}
.description-scheda-corso > h2 {
    color: rgba(0, 75, 90, 1);
    font-weight: 500;
    font-size: 20px;
}
.description-scheda-corso > p {
    font-size: 16px;
    width: 80%;
}


.pianodistudi-scheda-corso{
    background-color: rgba(251, 251, 251, 1);
    padding: 25px 15px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 80%;
    font-family: 'Inter', sans-serif;
}
.pianodistudi-scheda-corso > h3 {
    color: rgba(0, 75, 90, 1);
    font-weight: 500;
    font-size: 20px;
}
.pianodistudi-item {
    overflow: hidden;
    transition: height 0.3s ease;
    padding: 5px;
    border-bottom: 1px solid rgba(0, 75, 90, 0.2);
    margin: 20px 0;
}
.accordion-content{
    padding-left: 0px;
    width: 100%;
}
.pianodistudi-item.aperto {
    height: auto; 
    transition: 0.3s;
}
.accordion-header{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.aperto-header{
    justify-content: space-between;
}
.aperto-header > span {
    text-decoration: underline !important;
}
.accordion-header > span {
    color: rgba(0, 75, 90, 1);
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
}
.accordion-header > img {
    margin-left: 10px;
    width: 3%;
}
.closesvg{
    height: 35px;
}


.recensioni-scheda-corso{
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 80%;
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.recensioni-scheda-corso > img:last-of-type {
    width: 16%;
    height: auto;
}
.recensioni-scheda-corso > h3 {
    color: rgba(0, 75, 90, 1);
    font-size: 20px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
}

.slider-recensioni {
    display: flex;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 20px;
    cursor: grab;
}
.slider-recensioni div{
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.slider-recensioni:active {
    cursor: grabbing;
  }
.slider-recensioni img {
    width: 100%; 
}    


.prezzocomparato-scheda-corso{
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: 'Inter', sans-serif;
}
.prezzocomparato-scheda-corso > h3 {
    color: rgba(0, 75, 90, 1);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}
.table{
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 10px 2px rgba(0, 75, 90, 0.1);
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 80px;
}
.top-table{
    display: flex;
    background-color: rgba(0, 75, 90, 1);
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.top-table > div:first-of-type{
    width: 40%;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    text-align: center;
}
.top-table > div:last-of-type{
    width: 60%;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    text-align: center;
}
.top-table p {
    color: white;
}
.table-item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    color: rgba(0, 75, 90, 1);
    border-bottom: 1px solid rgba(0, 75, 90, 0.2);
}
.table-item:nth-of-type(3){
    background-color: rgba(0, 75, 90, 0.1);
}
.table-item:nth-of-type(3) > div > p{
    font-size: 18px;
    text-decoration: underline;
    font-weight: 500;
}
.table-item > div:first-of-type{
    width: 40%;
    border-right: 1px solid rgba(0, 75, 90, 0.2);
    text-align: center;
}
.table-item > div:last-of-type{
    width: 60%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.table-item button {
    color: white;
    background-color: rgba(0, 75, 90, 1);
    border: none;
    padding: 6px 16px;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    border-radius: 20px;
    cursor: pointer;
}



.mappa-scheda-corso{
    width: 95%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: 'Inter', sans-serif;
}
.mappa-scheda-corso > h3 {
    color: rgba(0, 75, 90, 1);
    font-size: 20px;
    font-weight: 400;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 10px;
}
.mappa-scheda-corso > h2 {
    color: rgba(0, 75, 90, 1);
    font-size: 22px;
    font-weight: 500;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 10px;
}
.mappa-scheda-corso > h2 > span {
    text-decoration: underline;
}

.dopo-scheda-corso{
    width: 80%;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 70px;
    font-family: 'Inter', sans-serif;
}
.dopo-scheda-corso > h3 {
    color: rgba(0, 75, 90, 1);
    font-size: 20px;
    font-weight: 500;
}
.dopo-scheda-corso > p {
    font-weight: 400;
}
.dopo-item > div {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    align-items: center;
}
.dopo-item > div > p {
    color: rgba(0, 75, 90, 1);
}
.dopo-item > div > img {
    width: 40px;
    height: auto;
}

.servizi-scheda-corso {
    background-color: rgba(251, 251, 251, 1);
    padding: 25px 15px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 70px;
    width: 80%;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}
.servizi-scheda-corso > h3 {
    text-align: center;
    color: rgba(0, 75, 90, 1);
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
}
.servizi {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    gap: 3rem;
    margin: 10px 0;
}
.servizi > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    width: 80%;
}
.servizi > div > p {
    color: rgba(0, 75, 90, 1);
}

.sbocchi-div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 80%;
    gap: 1rem;
}
.sbocchi-div > p {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}
.sbocchi-div > p > img {
    width: 28px;
    height: auto;
}
.sbocchi-div > p > span {
    color: rgba(0, 75, 90, 1);
    font-weight: 450;
}


.info-scheda-corso{
    padding: 25px 15px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 70px;
    width: 80%;
    font-family: 'Inter', sans-serif;
}
.info-scheda-corso > h3 {
    color: rgba(0, 75, 90, 1);
    font-size: 20px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}
.info-scheda-corso > h3 > img {
    background-color: rgba(0, 75, 90, 1);
    padding: 8px;
    border-radius: 50%;
}


.top-table-consigliati{
    display: flex;
    background-color: rgba(0, 75, 90, 1);
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.top-table-consigliati > div:first-of-type{
    width: 50%;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    text-align: center;
}
.top-table-consigliati > div:last-of-type{
    width: 50%;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    text-align: center;
    display: flex;
    justify-content: center;
}
.top-table-consigliati p {
    color: white;
}
.top-table-consigliati > div:last-of-type > div:first-of-type{
    width: 50%;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.top-table-consigliati > div:last-of-type > div:last-of-type{
    width: 50%;
}
.table-item-consigliati{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    color: rgba(0, 75, 90, 1);
    border-bottom: 1px solid rgba(0, 75, 90, 0.2);
}
.table-item-consigliati > div:first-of-type{
    width: 50%;
    text-align: left;
}
.table-item-consigliati > div:first-of-type > p {
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}
.table-item-consigliati > div:last-of-type{
    width: 50%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.table-item-consigliati > div:last-of-type > div:first-of-type{
    border-right: 1px solid rgba(0, 75, 90, 0.2);
    border-left: 1px solid rgba(0, 75, 90, 0.2);
    width: 50%;
    padding: 12px 0;
}
.table-item-consigliati > div:last-of-type > div:first-of-type > p {
   font-weight: 500;
}
.table-item-consigliati > div:last-of-type > div:last-of-type{
    width: 50%;
}


.bottom-fix{
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    background-color: #044B5A;
    padding: 17px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottom-fix > img {
    height: 50px;
    width: 50px;
    margin-left: 30px;
    position: absolute;
    left: 14px;
    top: 20px;
}
.bottom-fix > div {
    width: 100%;
    display: flex;
    justify-content: center;
}
.bottom-fix > div > button {
    background-color: white;
    color: rgba(0, 75, 90, 1);
    border: none;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    border-radius: 30px;
    cursor: pointer;
}



@media screen and (max-width: 600px) {
    .top-scheda-corso {
        padding: 0 10px;
    }
    .top-scheda-corso h2 {
        font-size: 15px;
        text-align: center;
        width: 90%;
    }
    .red-other {
        width: 90%;
    }
    .red-other > p {
        margin: 0 auto;
        width: 90%;
        margin-bottom: -20px;
        margin-top: -20px;
    }


    .image-logo{
        display: none;
        margin-top: 0;
    }
    .image-logo-mobile, 
    .image-logo-mobile2{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .image-logo-mobile2{
        margin-bottom: 30px;
    }
    .image-logo-mobile > img {
        width: 130%;
        height: auto;
    }
    .image-logo-mobile2 > img {
        width: 73%;
        height: auto;
    }

    .title-scheda-corso{
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 90%;
        margin-top: 0px;
    }
    .title-scheda-corso > h1 {
        width: 100%;
        text-align: center;
        font-size: 18px;
    }
    .intestazione {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
    }
    .intestazione > .image-logo-mobile{
        border: 1px solid #004B5A;
        border-radius: 50%;
        min-width: 60px;
        min-height: 60px;
        max-height: 60px;
        height: 60px;
        width: 60px;
    }
    .intestazione > h1 {
        width: 65%;
        font-size: 16px;
        text-align: left;
        margin-left: 10px;
    }
    .tag-scheda-corso{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        gap: 0.3rem;
        flex-wrap: wrap;
    }
    .tag-scheda-corso > div {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tag-scheda-corso span {
        padding: 5px 10px;
        margin: 0 6px;
        font-weight: 500;
        font-size: 11px;
        width: fit-content;
        white-space: nowrap;
    }
    .description-scheda-corso > p {
        font-size: 14px;
        width: 100%;
    }
    .accordion-header > span {
        font-size: 15px;
    }
    .accordion-header > img {
        margin-left: 10px;
        width: 10%;
    }
    .accordion-content > p{
        font-size: 14px;
    }
    .closesvg{
        height: 30px;
    }


    .recensioni-scheda-corso{
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .recensioni-scheda-corso > img:last-of-type {
        width: 50%;
        height: auto;
    }
    .recensioni-scheda-corso > h3 {
        text-align: center;
    }


    .slider-recensioni {
        width: 90%;
    }

    .prezzocomparato-scheda-corso{
        width: 90%;
    }
    .top-table p {
        color: white;
        font-size: 16px;
    }
    .table-item > div:first-of-type > p {
        font-size: 14px;
    }
    .table-item:nth-of-type(3) > div > p{
        font-size: 16px;
        text-decoration: underline;
    }
    .table-item > div:last-of-type > p {
        font-size: 14px;
    }
    .table-item:nth-of-type(3) > div:last-of-type > p {
        font-size: 16px;
    }
    .table-item button {
        font-size: 14px;
    }

    .mappa-scheda-corso > h3 {
        font-size: 16px;
    }
    .mappa-scheda-corso > h2 {
        font-size: 20px;
    }



    .dopo-scheda-corso{
        width: 90%;
    }
    .dopo-scheda-corso > p {
        font-size: 16px;
    }
    .dopo-item > div > img {
        width: 18px;
        height: auto;
    }
    .dopo-item > div {
        gap: 1rem;
    }
    .dopo-item > div > p {
        font-size: 15px;
    }

    .servizi-scheda-corso {
        width: 90%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .servizi {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
        margin: 10px 0;
    }
    .servizi > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.4rem;
        width: 100%;
    }
    .servizi > div > p {
        color: rgba(0, 75, 90, 1);
        font-size: 14px;
    }
    .servizi > div > img {
        width: 30px;
        height: auto;
    }
    

    .sbocchi-div{
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        gap: 1px;
    }
    .sbocchi-div > p {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
    }
    .sbocchi-div > p > img {
        width: 20px;
        height: auto;
    }
    .sbocchi-div > p > span {
        color: rgba(0, 75, 90, 1);
        font-weight: 400;
        font-size: 14px;
    }
    .info-scheda-corso {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .info-scheda-corso > p {
        font-size: 14px;
    }


    .top-table-consigliati p {
        color: white;
        font-size: 16px;
    }
    .table-item-consigliati > div:first-of-type > p {
        font-size: 14px;
    }
    .table-item-consigliati > div:last-of-type > div:first-of-type > p {
       font-size: 14px;
    }


    .bottom-fix > img {
        height: 40px;
        width: 40px;
        margin-left: 20px;
    }
    .bottom-fix > div {
        width: 70%;
    }
}